body {
  font: 1.8em/2 sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 1.8em;
  font-family: Lato;
  /* font-family: 'Galactica Pyramid Card Game', sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;

  /* position: relative; */
  overflow-x: hidden;
  /* background-image: url("../src/assest/new4.gif"); */
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  max-width: 70% !important ;
  margin: 0 auto;
}

#-js {
  position: absolute;
  width: 100%;
  height: 100%;
}
#particles canvas {
  position: absolute;
  width: 100%;

  z-index: 1;
}
@media (max-width: 600px) {
  * {
    font-size: 14px !important;
  }
  #particles canvas {
    position: absolute;
    width: 100%;

    z-index: 1;
  }
}
@media (max-width: 800px) and (min-width: 700px) {
  #particles canvas {
    position: absolute;
    width: 100%;

    z-index: 1;
  }
}

@media (max-width: 600px) {
  .container {
    max-width: 90% !important ;
    margin: 0 auto !important;
  }
}


