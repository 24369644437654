.stake-body {
  width: 100%;
  height: 70vh;
  display: flex;
}
.collection-heading1 {
  font-family: "Press Start 2P";
  color: rgb(0, 184, 255);
  font-weight: 700;
  font-size: 30px !important;
  /* line-height: 6rem; */
  text-transform: uppercase;
}

.nft-img {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  background-color: transparent;
  /* padding: 10px; */
  /* grid-column-gap: 6.7%; */
  margin-top: 5%;
}

.adjust-img {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 360px; */
  width: 100%;
}
.adjust-img1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 360px;
  width: 100%;
}
.adjust-img2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 360px; */
  width: 220px;
  max-width: 220px;
  background: #d33232;
}
.modal-body {
  color: black;
}

.adjust-img:hover {
  background: #d33232;
  -webkit-transition: background 2s; /* For Safari */
  transition: background 2s; /* For modern browsers */
}

#head-button2 {
  background: rgb(149, 214, 238);
  color: #07081d;
  padding: 6px 28px;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.3s;
  display: inline-block;
  font-weight: 600;
  /* margin-left: -235px; */
  border: 0px !important;
  font-size: 16px;
  margin-bottom: 0%;
  position: relative;
  line-height: 40px !important;
  cursor: pointer;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .button-top {
    margin-top: 0px !important;
  }

  .nft-img {
    display: grid;
    grid-template-columns: none !important;
    grid-template-rows: auto;
    background-color: transparent;
    /* grid-template-columns: auto auto auto auto; */

    /* padding: 10px; */
    grid-column-gap: 7.5%;
    margin-top: 5%;
  }
  .stake-your-penguin {
    justify-content: center;
  }
  .loader-div {
    position: relative !important;
    left: 150px !important;
  }
}
.modal-footer {
  border-top: none;
}
.modal-content {
  background-color: rgb(7, 8, 29) !important;
}

.reward-show {
  font-family: "Press Start 2P";
  color: rgb(0, 184, 255);
  font-weight: 700;
  font-size: 30px !important;
  /* line-height: 6rem; */
  text-transform: uppercase;
  text-align: center;
}
.button-top {
  margin-top: -20px;
  z-index: 1;
  margin-left: 10px;
}
.stake-your-penguin {
  display: flex;
  font-size: 15px;
}
.tokenId {
  background-color: red;
}

@media screen and (max-width: 991px) {
  .nft-img {
    grid-template-columns: 1fr 1fr;
  }
  .button-top {
    margin-top: 0px !important;
  }
  .loader-div {
    position: relative;
    left: 250px !important;
  }
}

.loader-div {
  position: relative;
  left: 500px;
}

.img-select {
  width: 200px;
  height: 200px;
  margin: 5%;
}
.img-select1 {
  width: 200px;
  height: 200px;
  margin: 5%;
  opacity: 0.5;
}
.tokenId1 {
  font-size: 23px;
  margin-bottom: 0 !important;
  font-weight: bold;
}

.adjusting-button {
  display: flex;
}

@media screen and (max-width: 767px) {
  .tokenId1{
    font-size: 23px !important;
  }
  .adjusting-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 10%;
  }
}

@media screen and (max-width: 820px) {
  .adjusting-button {
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    justify-content: center;
    margin-top: 5%;
  }
  .stake-your-penguin {
    justify-content: center;
  }
}
.container1 {
  max-width: 72% !important ;
  margin: 0 auto;
}
